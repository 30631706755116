import React, { useContext, useState } from "react";
import gameContext from "../../gameContext";
import gameService from "../../services/gameService";
import socketService from "../../services/socketService";
import {
	RoomHeader,
	WelcomeMessage,
	JoinButton,
	JoinRoomContainer,
	RoomIdInput,
	RoomsBox,
	RoomPar,
	EncreuatForm,
	RoomsBoxAtt,
} from "./JoinRoom.styles";

interface IJoinRoomProps {}

export function JoinRoom(props: IJoinRoomProps) {
	const [roomName, setRoomName] = useState("");

	const { setRoom, room } = useContext(gameContext);

	const [isJoining, setJoining] = useState(false);

	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	const { setInRoom, isInRoom } = useContext(gameContext);

	const handleRoomNameChange = (e: React.ChangeEvent<any>) => {
		const value = e.target.value.toUpperCase();
		setRoomName(value);
		setRoom(value);
	};

	const joinRoom = async (e: React.FormEvent) => {
		e.preventDefault();

		const socket = socketService.socket;
		if (!roomName || roomName.trim() === "" || !socket) return;

		setJoining(true);

		const joined = await gameService.joinGameRoom(socket, roomName).catch((err) => {
			alert(err);
		});

		if (joined) setInRoom(true);
		setJoining(false);
	};

	return (
		<form onSubmit={joinRoom}>
			<JoinRoomContainer>
				<RoomsBox>
					<WelcomeMessage>Benvingut/da a l'Encreua't!</WelcomeMessage>

					<RoomPar>
						<p>Un joc on hauràs de trobar les paraules correctes deduïnt-les amb les descripcions que t'anirem donant...</p>

						<p>
							Però això no és tot, hauràs de descobrir-ne més i més ràpid de les que en descobreixi el teu contrincant per a obtenir més
							punts i guanyar la partida!!
						</p>
					</RoomPar>
				</RoomsBox>
				<RoomsBox>
					<RoomHeader>
						<p>
							<strong>Opció 1 Crear Sala:</strong>
						</p>

						<p>Escriu un nom o un número i crea't una sala apretant el botó ENCREUAT i comparteix el teu id</p>

						<p>
							<strong>Opció 2 Entrar a Sala:</strong>
						</p>
						<p>Introdueix l'id del teu contrincant i comença a jugar!</p>
					</RoomHeader>

					<EncreuatForm>
						<RoomIdInput placeholder="123" value={roomName} onChange={handleRoomNameChange} />
						<JoinButton type="submit" disabled={isJoining}>
							{isJoining ? "CONECTANT..." : "ENCREUA'T"}
						</JoinButton>
					</EncreuatForm>
				</RoomsBox>
				<RoomsBoxAtt>
					<h4>Sales Públiques</h4>
					<p>Pròximament...</p>
				</RoomsBoxAtt>
				<RoomsBoxAtt>
					<h4>Sales Privades</h4>
					<p>Pròximament...</p>
				</RoomsBoxAtt>
			</JoinRoomContainer>
			<span className="d-flex container justify-content-center align-content-center small small text-muted pt-3">
				Enginyat i programat per
				<a href="https://www.linkedin.com/in/xavi-torner/" className="d-flex text-muted ps-1">
					Xavi Torner
				</a>
			</span>
		</form>
	);
}
