import { Modal } from "react-bootstrap";
import styled from "styled-components";

export const InfoModalWrapper = styled.div`
	display: flex;
`;
export const ModalInstruccions = styled(Modal)`
	.Body {
		background: #ffffff35;
	}
	h5 {
		font-size: 0.9rem;
		color: red;
	}
	p {
		display: flex;
		font-size: 0.7rem;
	}
`;
