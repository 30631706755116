import { Modal, Button } from "react-bootstrap";
import { InfoModalWrapper, ModalInstruccions } from "./InfoModal.styles";

export default function InfoModal({ handleModal }) {
	return (
		<InfoModalWrapper>
			<ModalInstruccions show={true} onHide={handleModal} size="lg">
				<ModalInstruccions.Header closeButton>
					<h5>Instruccions per a l' encreua't</h5>
				</ModalInstruccions.Header>
				<ModalInstruccions.Body>
					<p>El joc comença quan dues persones entren a la mateixa sala.</p>
					<p>Hi ha 5 torns on cada contrincant ha de deduir la mateixa paraula amb el mínim temps possible.</p>
					<p>S'hi tenen en compte els accents, les dièresis i les terminacions de gènere.</p>
					<p>
						Si la paraula conté terminació de gènere, automàticament apareixerà un guió (-) [genero-terminació genero femení] \ exemple:
						['punxat-ada'].
					</p>
					<p>Tens 60 segons per respondre, però pensa que has d'emprar-ne els mínims possibles, per aconseguir la màxima puntuació.</p>
					<p>Si passes no aconsegueixes cap punt.</p>
					<p>Si empates en temps i resposta aconsegueixes dos punts.</p>
					<p>Si l'encertes només tu aconsegueixes 3 punts.</p>
					<p>Si empates en resposta i ets més ràpid aconsegueixes 3 punts.</p>
					<p>Si empates en resposta i ets més lent aconsegueixes 1 punt.</p>
					<p>
						Un cop acaba el joc pots comparar les respostes de cadascú i veure les puntuacions assolides així com les respostes correctes
						segons l'IDEC.
					</p>
				</ModalInstruccions.Body>
				<ModalInstruccions.Footer>
					<Button className="btn-sm" variant="primary" onClick={handleModal}>
						Tancar
					</Button>
				</ModalInstruccions.Footer>
			</ModalInstruccions>
		</InfoModalWrapper>
	);
}
